@import 'normalize.css/normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Work+Sans:wght@500;600;700;800;900&display=swap');

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    font-family: 'Nunito', sans-serif;
}

#root, html {
    min-width: 100vw;
    width: 100%;
    min-height: 100vh;
    height: 100%;
}

#root * {
    box-sizing: border-box;
}
